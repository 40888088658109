<template>
  <div class="pull-left">
    <b-modal
      ref="my-modal"
      :title="this.item ? 'Засах' : 'Шинээр нэмэх'"
      hide-footer
    >
      <loading
        :active.sync="isLoading"
        :is-full-page="false"
        :color="this.$config.LOADER.color"
        :opacity="this.$config.LOADER.opacity"
        :background-color="this.$config.LOADER.backgroundColor"
        :z-index="10000"
      ></loading>
      <b-form @submit.stop.prevent="saveData()" v-if="form">
        <b-form-group label="Доод хэмжээ">
          <money
            id="input-loanMinAmount"
            class="form-control"
            v-model="form.loanMinAmount"
            type="text"
            required
            placeholder="Доод хэмжээ"
          ></money>
        </b-form-group>
        <b-form-group label="Дээд хэмжээ">
          <money
            id="input-loanMaxAmount"
            class="form-control"
            v-model="form.loanMaxAmount"
            type="text"
            required
            placeholder="Дээд хэмжээ"
          ></money>
        </b-form-group>
        <b-form-group label="Хүү">
          <money
            id="input-interest"
            suffix=" %"
            :precision="1"
            class="form-control"
            v-model="form.interest"
            type="text"
            required
            placeholder="Хүү"
          ></money>
        </b-form-group>
        <b-form-group label="Хугацаа">
          <money
            id="input-term"
            suffix=" сар"
            :precision="0"
            class="form-control"
            v-model="form.term"
            type="text"
            required
            placeholder="Хугацаа"
          ></money>
        </b-form-group>
        <b-form-group label="Олголтын шимтгэл">
          <money
            id="input-loanFeePercent"
            suffix=" %"
            :precision="0"
            class="form-control"
            v-model="form.loanFeePercent"
            type="text"
            required
            placeholder="Олголтын шимтгэл"
          ></money>
        </b-form-group>
        <b-form-group id="input-group-4" class="mb-0">
          <b-form-checkbox :value="true" v-model="form.isActive" switch
            >Идэвхтэй</b-form-checkbox
          >
        </b-form-group>
        <hr />
        <b-button type="submit" variant="success">
          Хадгалах
        </b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "Merchant.conditionEdit",
  props: ["item", "callback", "isEdit"],
  data: function() {
    return {
      isLoading: false,
      form: null,
    };
  },
  watch: {
    item: {
      handler(_item) {
        this.$data.form = this.item;
        this.showModal();
      },
      deep: true,
    },
  },
  methods: {
    initData: function() {
      this.$data.isLoading = false;
      this.$data.item = null;
    },
    showModal() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.initData();
      this.$refs["my-modal"].hide();
    },
    saveData: async function() {
      this.$data.isLoading = true;

      //console.log({
      //     id: this.$data.form.id,
      //     loanTypeId: this.$route.params.loanTypeId,
      //     data: JSON.stringify({
      //         loanMinAmount: this.$data.form.loanMinAmount,
      //         loanMaxAmount: this.$data.form.loanMaxAmount,
      //         interest: this.$data.form.interest,
      //         term: this.$data.form.term,
      //         loanFeePercent: this.$data.form.loanFeePercent,
      //         isActive: this.$data.form.isActive,
      //     })
      // })

      var response = await this.$http.post(
        this.$config.API_URL + "PurchaseWebService/condition_data_change",
        {
          id: this.$data.form.id,
          loanTypeId: this.$route.params.loanTypeId,
          data: JSON.stringify({
            loanMinAmount: this.$data.form.loanMinAmount,
            loanMaxAmount: this.$data.form.loanMaxAmount,
            interest: this.$data.form.interest,
            term: this.$data.form.term,
            loanFeePercent: this.$data.form.loanFeePercent,
            isActive: this.$data.form.isActive,
          }),
        },
        {
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        }
      );
      //console.log(response)
      this.$data.isLoading = false;
      this.hideModal();
      this.callback();
    },
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{staticClass:"col-md-3",attrs:{"no-body":""}},[_c('b-card',[_c('b-list-group',_vm._l((_vm.customerGroups),function(item,index){return _c('b-list-group-item',{key:index,attrs:{"to":{
            name: _vm.$route.name,
            params: { id: _vm.$route.params.id, loanTypeId: item.loanTypeId },
          }}},[_vm._v(_vm._s(item.customerGroupName))])}),1)],1)],1),_c('b-col',[_c('b-card',[_c('h4',[_vm._v("Зээлийн нөхцөл")]),_c('hr'),_c('b-row',[_c('b-col',{staticClass:"col-md-6"},[_c('loading',{attrs:{"active":_vm.loanConditionsLoading,"is-full-page":false,"color":this.$config.LOADER.color,"opacity":this.$config.LOADER.opacity,"height":20,"background-color":this.$config.LOADER.backgroundColor,"z-index":10000},on:{"update:active":function($event){_vm.loanConditionsLoading=$event}}}),(this.$route.params.loanTypeId)?_c('b-link',{on:{"click":function($event){return _vm.loanConditionEdit({
                id: 0,
                loanMinAmount: 0,
                loanMaxAmount: 0,
                interest: 0,
                term: 0,
                loanFeePercent: 0,
                isActive: false,
              })}}},[_vm._v("нэмэх")]):_vm._e(),_c('b-list-group',{staticClass:"mt-1"},_vm._l((_vm.loanConditions),function(item,index){return _c('b-list-group-item',{key:index},[_c('ConfigConditionItem',{attrs:{"item":item,"editback":_vm.loanConditionEdit,"callback":_vm.getLoanConditions}})],1)}),1),_c('ConfigConditionEdit',{attrs:{"item":_vm.editLoanCondition,"callback":_vm.getLoanConditions}})],1),_c('b-col',{staticClass:"col-md-6"},[_c('loading',{attrs:{"active":_vm.loanConditionsDtlLoading,"is-full-page":false,"color":this.$config.LOADER.color,"opacity":this.$config.LOADER.opacity,"height":20,"background-color":this.$config.LOADER.backgroundColor,"z-index":10000},on:{"update:active":function($event){_vm.loanConditionsDtlLoading=$event}}}),(this.$route.params.conditionId)?_c('b-link',{on:{"click":function($event){return _vm.loanConditionDtlEdit({
                id: 0,
                interest: 0,
                term: 0,
                isActive: false,
              })}}},[_vm._v("нэмэх")]):_vm._e(),_c('b-list-group',{staticClass:"mt-1"},_vm._l((_vm.loanConditionsDtl),function(item,index){return _c('b-list-group-item',{key:index},[_c('ConfigConditionDtlItem',{attrs:{"item":item,"editback":_vm.loanConditionDtlEdit,"callback":_vm.getLoanConditionsDtl}})],1)}),1),_c('ConfigConditionDtlEdit',{attrs:{"item":_vm.editLoanConditionDtl,"callback":_vm.getLoanConditionsDtl}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
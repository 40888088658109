var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":false,"color":this.$config.LOADER.color,"opacity":this.$config.LOADER.opacity,"background-color":this.$config.LOADER.backgroundColor,"height":30,"z-index":10000},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('div',{staticClass:"pull-right"},[_c('b-button',{staticClass:"mt-1 ml-3",attrs:{"variant":_vm.item.id == _vm.$route.params.conditionId ? 'success' : 'secondary',"to":{
        name: _vm.$route.name,
        params: {
          id: _vm.$route.params.id,
          loanTypeId: _vm.$route.params.loanTypeId,
          conditionId: _vm.item.id,
        },
      }}},[_c('i',{staticClass:"fa fa-chevron-right"})])],1),_c('div',{staticClass:"pull-right"},[_c('b-form-checkbox',{attrs:{"value":"true","switch":""},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}}),_c('b-link',{on:{"click":function($event){return _vm.editback(_vm.item)}}},[_vm._v("засах")])],1),_vm._v(" "+_vm._s(_vm._f("currecry")(_vm.item.loanMinAmount))+" - "+_vm._s(_vm._f("currecry")(_vm.item.loanMaxAmount))),_c('br'),_c('b',[_vm._v("Хүү:")]),_vm._v(" "+_vm._s(_vm.item.interest)+"% "),_c('b',{staticClass:"ml-3"},[_vm._v("Хугацаа:")]),_vm._v(" "+_vm._s(_vm.item.term)+" сар "),_c('b',{staticClass:"ml-3"},[_vm._v("Олголтын шимтгэл:")]),_vm._v(" "+_vm._s(_vm.item.loanFeePercent)+"% ")],1)
}
var staticRenderFns = []

export { render, staticRenderFns }